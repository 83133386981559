<template>
    <div>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle zap-text-white" href="#" id="notDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="fa fa-bell fa-lg"></span>
                <span v-show="unreadNotifications > 0" class="badge badge-pill badge-warning">{{unreadNotifications}}</span>
            </a>
            <ul class="dropdown-menu dropdown-messages dropdown-menu-right" aria-labelledby="notDropdown">
                <li class="notify-drop-title">
                    <div class="d-flex justify-content-between">
                        <span style="font-weight: bold;">Notificações</span>
                        <v-button v-if="unreadNotifications > 0"
                                  class="btn btn-xs btn-success"
                                  :loading="markReadLoading"
                                  v-tooltip="'Marcar todas como lidas'"
                                  @click.stop.prevent="markAllRead"
                        >
                            <i class="fa fa-check fa-sm"></i>
                        </v-button>
                    </div>
                </li>
                <!-- eslint-disable -->
                <li class="dropdown-divider"></li>
                <div class="drop-content">
                    <div v-if="notifications.length === 0">Sem notificações</div>
                    <template v-for="not in notifications">
                        <!--<router-link @click.native="markRead(not)"
                                     :to="{name: 'AlarmDetail', params: {id: not.alarmId}}"
                                     class="d-flex notification-item"
                                     :class="{'notification-read' : not.read}"
                        >-->
                        <div @click="markReadAndOpen(not)"
                             class="d-flex notification-item"
                             :class="{'notification-read' : not.read}">
                            <div class="d-inline-flex align-items-center justify-content-center" style="width:45px;">
                                <i class="fa fa-2x" :class="getProductIcon(not)"></i>
                            </div>
                            <div class="media-body">
<!--                                <small class="float-right">46h ago</small>-->
                                <div style="font-weight: bold;">{{not.title}}</div>
                                <div v-if="not.message" v-html="not.message"></div>
<!--                                <strong>Mike Loreipsum</strong> started following <strong>Monica Smith</strong>. <br>-->
                                <small class="text-muted">
                                    <dynamic-from-now :val="not.date"></dynamic-from-now>
                                </small>
                            </div>
                        </div>
                        <!--</router-link>-->
                        <li class="dropdown-divider"></li>
                    </template>
                </div>
                <!-- eslint-enable -->
            </ul>
        </li>
    </div>
</template>
<script>
    import axios from 'axios';
    import {mapGetters} from "vuex";
    import DynamicFromNow from "./DynamicFromNow";
    import VButton from "./VButton";
    export default {
        components: {
            DynamicFromNow,
            VButton
        },
        data () {
            return {
                markReadLoading: false,
            }
        },
        mounted () {
            // TODO load pending notifications, ou recebe por WS?
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters({
                notifications: 'notifications'
            }),
            unreadNotifications() {
                return this.notifications.filter(not => {
                    return !not.read
                }).length;
            }
        },
        methods: {
            markAllRead() {
                const self = this;
                this.markReadLoading = true;

                const dataObj = this.notifications.map(not => {
                        return not.id
                });
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AlarmController.markRead().url;
                axios.post(url, dataObj).then(function (/*response*/) {
                    // set data
                    self.notifications.forEach(not => not.read = true);
                    self.markReadLoading = false;
                }).catch(function (error) {
                    // handle error
                    self.markReadLoading = false;
                    self.handleAjaxError(error);
                });
            },
            markReadAndOpen(notification) {
                const self = this;
                const dataObj = [notification.id];
                // eslint-disable-next-line
                const url = jsRoutes.controllers.AlarmController.markRead().url;
                axios.post(url, dataObj).then(function (/*response*/) {
                    // set data
                    notification.read = true;
                }).catch(function (error) {
                    // handle error
                    self.handleAjaxError(error);
                });
                if (notification.alarmId) {
                    this.$router.push({name: 'AlarmDetail', params: {id: notification.alarmId}});
                } else {
                    this.$router.push({name: 'ProductUseDetail', params: {id: notification.productUseId}});
                }
            },
            getProductIcon(notification) {
                switch (notification.type) {
                    case "UBER":
                        return 'fa-taxi';
                    case "DRIIVEME":
                        return 'fa-car';
                    case "OSCAR_REPLY":
                    case "OSCAR_UPDATE":
                        return 'fa-wrench';
                    case "HABIT_ALARM":
                        return 'fa-wifi';
                    case "HAPI_REPLY":
                        return 'fa-euro-sign';
                    case "REATIA_REPLY":
                        return 'fa-home';
                    case "FLUXE_REPLY":
                        return 'fa-car-crash';
                    case "NETWORKS_UPDATE":
                        return 'fa-wrench';
                    case "TRUEKARE_ALARM":
                        return 'fa-laptop-medical';
                }
            },
        }
    }
</script>
<style scoped>
    .notification-read {
        color: #BDBDBD !important;
    }

    .notification-read .text-muted {
        color: #BDBDBD !important;
    }

    .notification-item {
        color: #495057;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .notification-item:hover {
        cursor: pointer;
        text-decoration: none;
        background-color: #f8f9fa;
    }

    .drop-content::-webkit-scrollbar-track {
        background-color: #F5F5F5;
    }

    .drop-content::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
    }

    .drop-content::-webkit-scrollbar-thumb {
        background-color: #ccc;
    }

    .drop-content {
        max-height: calc(100vh - 150px);
        overflow-y: scroll;
        padding-right: 10px;
        margin-right: -10px;
    }

    .btn-xs {
        padding: 0.20rem 0.30rem;
        font-size: 0.6rem;
        line-height: 1.2;
        border-radius: 0.2rem;
    }


    .notify-drop-title {
        /*border-bottom: 1px solid #e2e2e2;*/
        /*padding: 5px 15px 10px 15px;*/
        padding-bottom: 0.5rem;
    }

    .dropdown-messages {
        width: 340px;
        min-width: 0;
        margin-left: 5px;
        padding: 10px 10px 10px 10px;
    }

    @media (min-width: 768px) {
        .dropdown-messages {
            width: 440px;
            margin-left: auto;
        }
    }

    .media-body {
        overflow: hidden;
        display: block;
        width: auto;
        font-size: 12px;
    }

    .dropdown-divider {
        height: 0;
        /*margin: .5rem 0;*/
        margin: 0;
        overflow: hidden;
        border-top: 1px solid #e9ecef;
    }
</style>
