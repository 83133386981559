<template>
    <div class="modal" ref="themodal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Oficina - {{ requestData.oficinaNome }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div ref="map" class="map-container"></div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-link" @click="close">Fechar</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    var map = null; // google map obj
    // var bounds = null; // google bounds obj
    // var marker = null; // google map marker
    var infoWindow = null; // google map marker info window

    import $ from "jquery";

    export default {
        components: {
        },
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            requestData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
            }
        },
        mounted() {
            let self = this;
            $(this.$refs.themodal).on('hidden.bs.modal', function () {
                self.$emit('update:show', false);
            });
        },
        watch: {
            show() {
                if (this.show) {
                    $(this.$refs.themodal).modal('show');
                    map = null; // google map obj
                    // marker = null; // google map marker
                    infoWindow = null; // google map marker info window
                    this.setupMap();
                } else {
                    $(this.$refs.themodal).modal('hide');
                }
            },
        },
        computed: {
        },
        methods: {
            close() {
                this.$emit('update:show', false);
            },
            setupMap() {
                let center = {
                    lat: 39.6966529,
                    lng: -9.3860561,
                };
                if (this.requestData.oficinaLat &&
                    this.requestData.oficinaLon
                ) {
                    center.lat = this.requestData.oficinaLat;
                    center.lng = this.requestData.oficinaLon;
                }
                map = new window.google.maps.Map(this.$refs.map, {
                    center: center,
                    zoom: 10,
                });

                this.createrequestDataMarker();
            },
            createrequestDataMarker() {
                let marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.requestData.oficinaLat, this.requestData.oficinaLon),
                    map: map,
                });
                // bounds = new window.google.maps.LatLngBounds();
                // bounds.extend(marker.position);
                // map.fitBounds(bounds);

                // const mapsUrl = "https://www.google.com/maps/search/?api=1&query=" + this.requestData.oficinaLat + "," + this.requestData.oficinaLon;
                const mapsUrl = "https://www.google.com/maps/dir/?api=1&destination=" + this.requestData.oficinaLat + "," + this.requestData.oficinaLon;
                const requestDataContent = "<b>Nome: </b>" + this.requestData.oficinaNome + "<br/>" +
                    "<b>Morada: </b>" + this.requestData.oficinaMorada + ", " + this.requestData.oficinaCodigoPostal + " " + this.requestData.oficinaLocalidade + "<br/>" +
                    "<b>Telefone: </b>" + this.requestData.oficinaTelefone + "<br/>" +
                    "<b>Email: </b>" + this.requestData.oficinaEmail + "<br/>" +
                    "<div class='text-center'><a class='btn btn-primary mt-2 mb-2' href='" + mapsUrl + "' target='_blank'><i class='fa fa-external-link'></i> Abrir mapa</a></div>";
                marker.addListener('click', function () {
                    infoWindow = new window.google.maps.InfoWindow({
                        content: requestDataContent
                    })
                    infoWindow.open(map, marker);
                });
            },
        },
    }
</script>

<style scoped>
   .map-container {
       height: 400px;
       width: 100%;
   }
</style>
