<template>
    <div class="card">
        <div class="card-header">
            <h3><i class="fa fa-taxi"></i> Dar crédito Bolt</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <form v-on:submit.prevent="makeRequest" id="prodUberForm" novalidate>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.externalRef.$error }">
                            <label for="externalRef" class="control-label">Referência Externa *</label>
                            <input class="form-control" type="text" id="externalRef"
                                   :disabled="loading"
                                   :class="{'is-invalid' : $v.requestData.externalRef.$error}"
                                   v-model="$v.requestData.externalRef.$model"/>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.externalRef.$error && !$v.requestData.externalRef.required">
                            {{ $t("root.required") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.externalRef.$error && !$v.requestData.externalRef.maxLength">
                            {{
                                    $t("root.generic_max_size", {max: $v.requestData.externalRef.$params.maxLength.max})
                                }}</span>
                        </div>

                        <div class="form-group">
                            <label for="firstName" class="control-label">{{ $t("ProductUseBolt.label.name") }} *</label>
                            <input class="form-control" type="text" id="firstName"
                                   :class="{'is-invalid' : $v.requestData.name.$error}"
                                   :disabled="loading"
                                   v-model="$v.requestData.name.$model"/>
                            <span class="invalid-feedback" v-show="!$v.requestData.name.required">
                        {{ $t("root.required") }}</span>
                            <span class="invalid-feedback" v-show="!$v.requestData.name.maxLength">
                        {{ $t("root.generic_max_size", {max: $v.requestData.name.$params.maxLength.max}) }}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.phone.$error }">
                            <label for="phone" class="control-label">{{ $t("ProductUseUber.telefone") }} *</label>
                            <input class="form-control" type="text" id="phone"
                                   :class="{'is-invalid' : $v.requestData.phone.$error}"
                                   :disabled="loading"
                                   v-model="$v.requestData.phone.$model"/>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.required">
                        {{ $t("root.required") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.numeric">
                        {{ $t("root.onlynumbers") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.minLength">
                        {{ $t("root.generic_min_size", {min: $v.requestData.phone.$params.minLength.min}) }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.maxLength">
                        {{ $t("root.generic_max_size", {max: $v.requestData.phone.$params.maxLength.max}) }}</span>
                        </div>

                        <div class="form-group"
                             :class="{ 'has-error': $v.requestData.date.$error }">
                            <label class="control-label">Data *</label>
                            <date-picker :value.sync="requestData.date"
                                         :start-date="startDate"
                                         :end-date="endDate"
                                         :disabled="loading"
                                         :class="{'is-invalid': $v.requestData.date.$error}"
                                         :error-class="{'is-invalid': $v.requestData.date.$error}"></date-picker>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.date.$error && !$v.requestData.date.required">
                                {{ $t("root.required") }}</span>
                        </div>

                        <div class="form-group"
                             :class="{ 'has-error': $v.requestData.credit.$error }">
                            <label class="control-label">Crédito *</label>
                            <input type="hidden" v-model="requestData.credit" :class="{'is-invalid' : $v.requestData.credit.$error}"/>
                            <b-select class="form-control"
                                      :items="credits"
                                      :value.sync="requestData.credit"
                                      :disabled="loading"
                                      labelfield="id"
                                      data-size="4"
                                      data-width="100%"
                                      data-none-selected-text="Nenhum Seleccionado"
                            >

                            </b-select>
<!--                            <select class="form-control"
                                    size="10"
                                    v-model="requestData.credit"
                                    :disabled="loading"
                                    :class="{'is-invalid': $v.requestData.credit.$error}"
                            >
                                <option v-for="credit in credits" :key="credit" :value="credit">{{ id }}</option>
                            </select>-->
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.credit.$error && !$v.requestData.credit.required">
                                {{ $t("root.required") }}</span>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex mb-2 justify-content-end">
                                <div class="">
                                    <v-button type="submit"
                                              class="btn btn-primary zap-btn-rosa"
                                              :loading="loading"
                                    >
                                        Dar crédito
                                    </v-button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

import VButton from "@/components/VButton";
import moment from "moment";
import {getQueryParameterByName, showSuccessDialog} from "@/utils";
import DatePicker from "@/components/DatePicker";
import $ from "jquery";
import toastr from "toastr";
import {i18n} from "@/main";
import BSelect from "@/components/BSelect";

export default {
    components: {
        BSelect,
        DatePicker,
        VButton,
    },
    mixins: [
    ],
    data() {
        return {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
            credits: [
                { id: "15"},
                { id: "20"},
                { id: "25"},
                { id: "30"},
                { id: "35"},
                { id: "40"},
                { id: "45"},
                { id: "55"},
                { id: "65"},
                { id: "70"},
                { id: "80"},
                { id: "85"},
                { id: "95"},
                { id: "105"},
                { id: "110"},
                { id: "115"},
                { id: "120"},
                { id: "125"},
                { id: "130"},
                { id: "135"},
                { id: "140"},
                { id: "145"},
                { id: "150"},
                { id: "160"},
                { id: "165"},
                { id: "170"},
                { id: "180"},
                { id: "190"},
                { id: "195"},
                { id: "200"},
                { id: "205"},
                { id: "215"},
                { id: "230"},
                { id: "235"},
                { id: "245"},
                { id: "255"},
                { id: "260"},
                { id: "280"},
                { id: "285"},
                { id: "290"},
                { id: "300"},
                { id: "305"},
                { id: "315"},
                { id: "320"},
                { id: "325"},
                { id: "330"},
                { id: "340"},
                { id: "355"},
                { id: "360"},
                { id: "365"},
                { id: "370"},
                { id: "400"},
                { id: "405"},
                { id: "425"},
                { id: "435"},
                { id: "445"},
                { id: "470"},
                { id: "490"},
                { id: "505"},
                { id: "530"},
                { id: "540"},
                { id: "570"},
                { id: "610"},
            ],

            requestData: {
                auth: null,
                oid: null,
                externalRef: null,
                name: null,
                phone: null,
                date: null,
                credit: null,
            },

            loading: false,
        }
    },
    validations() {
        return {
            requestData: {
                externalRef: {
                    required,
                    maxLength: maxLength(255),
                },

                name: {
                    required,
                    maxLength: maxLength(500)
                },
                phone: {
                    required,
                    numeric,
                    minLength: minLength(9),
                    maxLength: maxLength(9)
                },
                date: {
                    required,
                },
                credit: {
                    required
                }
            },
        }
    },
    mounted() {
        // http://localhost:9000/bolt-creditar?oid=2&token=sTekcuqpSQ8nPylALHgVVn6mpmhe4hhm&sid=123&n=gustavo%20santos&d=2022-10-01&t=123456789&c=15#/
        this.requestData.date = this.date;
        // this.updateTimes()
        // const self = this;

        /*
            https://ezap.jbaysolutions.com/bolt-creditar?auth=<token>&sid=<salesforce-id>&n=<nome>&t=<telefone>&d=<data>&g=<grupo>
            - auth - token de autenticação que iremos fornecer
            - sid - id do processo salesforce
            - n - nome
            - t - telefone
            - d - data credito
            - g - grupo bolt (id do grupo bolt ou valor, ainda a definir)
         */
        this.requestData.oid = getQueryParameterByName("oid");
        this.requestData.auth = getQueryParameterByName("auth");
        this.requestData.externalRef = getQueryParameterByName("sid");
        this.requestData.name = getQueryParameterByName("n");
        this.requestData.phone = getQueryParameterByName("t");
        this.requestData.date = getQueryParameterByName("d");
        this.requestData.credit = getQueryParameterByName("g");
    },
    beforeDestroy() {
    },
    watch: {},
    computed: {
    },
    methods: {
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        handleAjaxError(error, title) {
            if (error.response !== undefined) {
                if (error.response.data) {
                    if (!this.isJson(error.response.data)) {
                        if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                            const errorEl = $(error.response.data).find("#error");
                            let message = ''
                            if (errorEl !== null && errorEl.length > 0)
                                message = $(error.response.data).find("#error").html();
                            else {
                                message = $(error.response.data).filter("title").text();
                            }
                            toastr.error(message, title ? title : i18n.tc('error.general_title'))
                        } else if (error.response.data) {
                            toastr.error(error.response.data, title ? title : i18n.tc('error.general_title'))
                        } else {
                            toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                        }
                    } else {
                        toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // if (error.response.data.msg) {
                        //     toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // }
                        console.log(JSON.stringify(error.response.data))
                    }
                } else {
                    toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.responseText !== undefined) {
                if (error.responseText.trim().length > 0) {
                    toastr.error(error.responseText, title ? title : i18n.tc('error.general_title'))
                } else {
                    toastr.error(error.statusText, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.message) {
                toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
            } else {
                toastr.error(error, title ? title : i18n.tc('error.general_title'))
            }
        },
        makeRequest() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // console.log("### INVALID");
                return;
            }
            const self = this;
            self.loading = true;
            //console.log("makeRideRequest => " + JSON.stringify(ride))

            // eslint-disable-next-line
            const url = jsRoutes.controllers.ProductBoltController.boltCredit().url;
            axios.post(url, this.requestData).then(() => {
                // set data
                self.loading = false;
                showSuccessDialog("Sucesso", "Crédito atribuido!");
                // TODO clean form
            }).catch(function (error) {
                self.loading = false
                // handle error
                self.handleAjaxError(error);
            });
        },
    }
}
</script>

<style scoped>

</style>
