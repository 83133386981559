<template>
    <div class="container-fluid mb-3">
        <div class="row">
            <div class="col-md-8 col-sm-12 mx-auto">
                <div class="card mt-2">
                    <div class="card-header">
                        <!-- TODO MENSAGENS? -->
                        <h2>Pedido Oscar {{uuid}}</h2>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <label for="data" class="col-sm-3 col-form-label">Data</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="data" :value="requestData.data">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="nome" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.nome_cliente")}}</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="nome" :value="requestData.nome">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="telefone" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.telefone_cliente")}}</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="telefone" :value="requestData.telefone">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="morada" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.morada_cliente")}}</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="morada" :value="requestData.morada">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="descricao" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.descricao")}}</label>
                            <div class="col-sm-9">
                                <span class="form-control-plaintext" id="descricao">{{requestData.descricao}}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="budget" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.budget")}}</label>
                            <div class="col-sm-9">
                                <span class="form-control-plaintext" id="budget">{{requestData.budget}}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">{{$t("ProductUseOscar.relatorio_peritagem")}}</label>
                            <div class="col-sm-9">
                                <a :href="downloadFicheiroUrl" class="btn btn-primary zap-btn-rosa">
                                    <i class="fa fa-download"></i> Consultar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2" v-if="requestData.status === 'PENDING'">
                    <div class="card-header">
                        <!-- TODO MENSAGENS? -->
                        <h3>Resposta</h3>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="makeReply" id="oscarReplyForm" novalidate>
                            <div class="form-group row"  :class="{ 'has-error': $v.responseData.price.$error }">
                                <label for="price" class="col-sm-3 col-form-label">Preço</label>
                                <div class="col-sm-9">
                                    <input class="form-control" type="text" id="price"
                                           :class="{'is-invalid' : $v.responseData.price.$error}"
                                           v-model="$v.responseData.price.$model"/>
                                    <span class="invalid-feedback" v-show="$v.responseData.price.$error && !$v.responseData.price.required">
                                    {{$t("root.required")}}</span>
                                    <span class="invalid-feedback" v-show="$v.responseData.price.$error && !$v.responseData.price.numeric">
                                    {{$t("root.onlynumbers")}}</span>
                                </div>
                            </div>
                            <div class="form-group row"  :class="{ 'has-error': $v.responseData.description.$error }">
                                <label class="col-sm-3 col-form-label">Descrição</label>
                                <div class="col-sm-9">
                                    <textarea-autosize class="form-control"
                                                       v-model="responseData.description"
                                                       :class="{'is-invalid' : $v.responseData.description.$error}"
                                                       :min-height="80"
                                                       :max-height="300">
                                    </textarea-autosize>
                                    <span class="invalid-feedback" v-show="$v.responseData.description.$error && !$v.responseData.description.required">
                                    {{$t("root.required")}}</span>
                                </div>
                            </div>

                            <div class="float-right">
                                <v-button type="submit" class="btn btn-primary zap-btn-rosa" :loading="saving">Responder</v-button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card mt-2" v-if="requestData.status !== 'PENDING'">
                    <div class="card-header">
                        <h3>Resposta
                            <div class="float-right">
                                <span class="badge badge-success">{{requestData.statusDesc}}</span>
                            </div>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <label for="data2" class="col-sm-3 col-form-label">Data</label>
                            <div class="col-sm-9">
                                <span class="form-control-plaintext" id="data2">{{requestData.responseDate}}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="descricao2" class="col-sm-3 col-form-label">{{$t("ProductUseOscar.descricao")}}</label>
                            <div class="col-sm-9">
                                <span class="form-control-plaintext" id="descricao2">{{requestData.descricao}}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="budget2" class="col-sm-3 col-form-label">Descrição</label>
                            <div class="col-sm-9">
                                <span class="form-control-plaintext" id="budget2">{{requestData.budget}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2" v-if="requestData.status === 'APPROVED' || requestData.status === 'ONGOING'">
                    <div class="card-body">
                        <div class="form-group row"  :class="{ 'has-error': $v.responseData.description.$error }">
                            <label class="col-sm-3 col-form-label">Descrição</label>
                            <div class="col-sm-9">
                                <textarea-autosize class="form-control"
                                                   v-model="responseData.description"
                                                   :class="{'is-invalid' : $v.responseData.description.$error}"
                                                   :min-height="80"
                                                   :max-height="300">
                                </textarea-autosize>
                                <span class="invalid-feedback" v-show="$v.responseData.description.$error && !$v.responseData.description.required">
                                    {{$t("root.required")}}</span>
                            </div>
                        </div>
                        <v-button v-if="requestData.status === 'APPROVED'"
                                  v-tooltip="'Alterar estado do pedido para em curso'"
                                  class="btn btn-primary zap-btn-rosa btn-block"
                                  :loading="saving"
                                  @click.native="updateStatus('ONGOING')"
                        >Em curso</v-button>
                        <div class="d-flex" v-if="requestData.status === 'ONGOING'">
                            <v-button v-tooltip="'Enviar actualização sobre o processo'"
                                      class="btn btn-primary zap-btn-rosa btn-lg"
                                      style="width:50%;"
                                      :loading="saving"
                                      @click.native="updateStatus('ONGOING')"
                            >Enviar actualização</v-button>
                            <v-button v-tooltip="'Dar como concluído'"
                                      class="btn btn-success btn-lg ml-2"
                                      style="width:50%;"
                                      :loading="saving"
                                      @click.native="updateStatus('CLOSED')"
                            >Concluir</v-button>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 mb-3">
                    <div class="card-header">
                        <h3>
                            Histórico

                        </h3>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="history in requestData.historyList" :key="history.id">
                            <div class="row">
                                <div class="col-3 text-right font-weight-bold">Data</div>
                                <div class="col-6">{{history.date}}</div>
                            </div>
                            <div class="row">
                                <div class="col-3 text-right font-weight-bold">Utilizador</div>
                                <div class="col-6">{{history.userName ? history.userName : 'Oscar'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-3 text-right font-weight-bold">Estado</div>
                                <div class="col-6">{{history.statusDesc}}</div>
                            </div>
                            <div class="row">
                                <div class="col-3 text-right font-weight-bold">Detalhes</div>
                                <div class="col-6">{{history.details}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>
<script>

    import {required, numeric} from "vuelidate/lib/validators";
    import axios from "axios";
    import {ajaxErrorNoAuthMixin} from "../mixins";
    import VButton from "../components/VButton";
    import VueTextareaAutosize from "vue-textarea-autosize";
    import Vue from 'vue';
    import {showSuccessDialog} from "../utils";
    Vue.use(VueTextareaAutosize);

    export default {
        mixins: [
            ajaxErrorNoAuthMixin,
        ],
        components: {
            VButton
        },
        data () {
            return {
                uuid: null,

                requestData: {
                    nome: null,
                    telefone: null,
                    morada: null,
                    descricao: null,
                    budget: null,
                    status: null,
                },

                responseData: {
                    price: null,
                    description: null
                },

                saving: false,
            }
        },
        validations() {
            if (this.requestData.status === 'PENDING') {
                return {
                    responseData: {
                        price: {
                            required,
                            numeric
                        },
                        description: {
                            required
                        },
                        // date: {
                        //     required,
                        // },
                    },
                }
            } else {
                return {
                    responseData: {
                        description: {
                            required
                        },
                    },
                }
            }
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.uuid = urlParams.get('uuid');
            document.title = "Pedido Oscar - E-Zap";
            this.loadData();
        },
        computed: {
            downloadFicheiroUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.ProductReplyOscarController.downloadFicheiroPeritagem(this.uuid).url;
            }
        },
        methods: {
            loadData() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyOscarController.getOscarRequestDetail(this.uuid).url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.requestData = response.data;
                }).catch(function (error) {
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            makeReply() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                const self = this;
                self.saving = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyOscarController.doProductReplyOscar().url;

                const dataObj = {
                    uuid: this.uuid,
                    price: this.responseData.price,
                    description: this.responseData.description
                }

                axios.post(url, dataObj).then(function (/*response*/) {
                    self.requestData.status = 'PENDING'
                    self.$v.$reset();
                    self.saving = false;
                    // TODO msgs
                    showSuccessDialog("Sucesso", "Resposta registada. Obrigado!")
                    self.loadData();
                }).catch(function (error) {
                    self.saving = false
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            updateStatus(status) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                const self = this;
                self.saving = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyOscarController.doUpdateStatusOscar().url;

                const dataObj = {
                    uuid: this.uuid,
                    status: status,
                    description: this.responseData.description
                }

                axios.post(url, dataObj).then(function (/*response*/) {
                    // set data
                    self.saving = false;
                    self.responseData.description = null;
                    self.$v.$reset();
                    // TODO msgs
                    showSuccessDialog("Sucesso", "Actualização registada. Obrigado!")
                    self.loadData();
                }).catch(function (error) {
                    self.saving = false
                    // handle error
                    self.handleAjaxError(error);
                });
            }
        }
    }
</script>
