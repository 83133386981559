import $ from "jquery";
import toastr from "toastr";
import {i18n} from "./main";
import {mapActions, mapGetters} from "vuex";

export const backMixin = {
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    }
}

export const productTypeIconMixin = {
    methods: {
        getProductIcon(product) {
            switch (product.type) {
                case "UBER":
                case "BOLT":
                    return 'fa-taxi';
                case "DRIIVEME":
                    return 'fa-car';
                case "OSCAR":
                    return 'fa-wrench';
                case "HABIT":
                    return 'fa-wifi';
                case "HAPI":
                    return 'fa-euro-sign';
                case "REATIA":
                    return 'fa-home';
                case "TRUEKARE":
                    return 'fa-laptop-medical';
                case "FLUXE":
                    return 'fa-car-crash';
                case "NETWORKS":
                    return 'fa-wrench';
                case "COFRE_DIGITAL":
                    return 'safe-icon';
                case "UNLOCKIT":
                    return 'fa-file-signature';

            }
        },
    }
}

export const ajaxErrorMixin = {
    computed: {
        ...mapGetters({
            showLoginModal: 'showLoginModal',
        }),
    },
    watch: {
        showLoginModal() {
            if (!this.showLoginModal) {
                if( this.refresh) {
                    this.refresh()
                }
            }
        },
    },
    methods: {
        ...mapActions(
            {
                setShowLoginModalExpired: 'setShowLoginModalExpired',
            },
        ),
        isAuthError(error) {
            if (error.status) {
                return error.status === 401 /*|| error.status === 403*/
            } else if (error.response && error.response.status) {
                return error.response.status === 401 /*|| error.response.status === 403*/
            }
            return false;
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        handleAjaxError(error, title) {
            if (this.isAuthError(error)) {
                this.setShowLoginModalExpired();
            } else {
                if (error.response !== undefined) {
                    if (error.response.data) {
                        if (!this.isJson(error.response.data)) {
                            if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                                const errorEl = $(error.response.data).find("#error");
                                let message = ''
                                if (errorEl !== null && errorEl.length > 0)
                                    message = $(error.response.data).find("#error").html();
                                else {
                                    message = $(error.response.data).filter("title").text();
                                }
                                toastr.error(message, title ? title : i18n.tc('error.general_title'))
                            } else if (error.response.data) {
                                toastr.error(error.response.data, title ? title : i18n.tc('error.general_title'))
                            } else {
                                toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                            }
                        } else {
                            toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                            // if (error.response.data.msg) {
                            //     toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                            // }
                            console.log(JSON.stringify(error.response.data))
                        }
                    } else {
                        toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                    }
                } else if (error.responseText !== undefined) {
                    if (error.responseText.trim().length > 0) {
                        toastr.error(error.responseText, title ? title : i18n.tc('error.general_title'))
                    } else {
                        toastr.error(error.statusText, title ? title : i18n.tc('error.general_title'))
                    }
                } else if (error.message) {
                    toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                } else {
                    toastr.error(error, title ? title : i18n.tc('error.general_title'))
                }
            }
        }
    }
}


export const ajaxErrorNoAuthMixin = {
    methods: {
        handleAjaxError(error, title) {
            if (error.response !== undefined) {
                if (error.response.data) {
                    if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                        const errorEl = $(error.response.data).find("#error");
                        let message = ''
                        if (errorEl !== null && errorEl.length > 0)
                            message = $(error.response.data).find("#error").html();
                        else {
                            message = $(error.response.data).filter("title").text();
                        }
                        toastr.error(message, title ? title : i18n.tc('error.general_title'))
                    } else if (error.response.data) {
                        toastr.error(error.response.data, title ? title : i18n.tc('error.general_title'))
                    } else {
                        toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                    }
                } else {
                    toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.responseText !== undefined) {
                if (error.responseText.trim().length > 0) {
                    toastr.error(error.responseText, title ? title : i18n.tc('error.general_title'))
                } else {
                    toastr.error(error.statusText, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.message) {
                toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
            } else {
                toastr.error(error, title ? title : i18n.tc('error.general_title'))
            }
        }
    }
}
