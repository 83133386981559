<template>
    <select ref="select" class="selectpicker" data-style="btn-white">
        <option v-for="item in items" :value="item.id" :key="item[valuefield]">{{item[labelfield]}}</option>
    </select>
</template>
<style>
.btn-white {
    color: #212529;
    background-color: white;
    border-color: #ced4da;
}
</style>
<script>
import $ from 'jquery'
import 'bootstrap-select/dist/css/bootstrap-select.min.css'
import 'bootstrap-select';
import 'popper.js'

// import { selectpicker } from 'bootstrap-select'
$.fn.selectpicker.Constructor.BootstrapVersion = '4';

export default {
    name: "BSelect",

    props: {
        value: {
            default() {
                return ''
            }
        },
        items: {
            type: Array,
            default() {
                return []
            }
        },
        valuefield: {
            type: String,
            default() {
                return "id"
            }
        },
        labelfield: {
            type: String,
            default() {
                return "text"
            }
        },
        disabled: {
            default() {
                return false
            }
        },
    },

    data: function () {
        return {};
    },

    watch: {
        items: function () {
            this.$nextTick(() => {
                if (this.value) {
                    let select = $(this.$refs.select);
                    // console.log("VALUE => '" + this.value + "'")
                    select.selectpicker("val", this.value);
                    // select.selectpicker("refresh");
                }
            })
        },
        value: function () {
            const select = $(this.$refs.select);
            const value = this.value+"";
            const pickerValue = select.selectpicker("val")+"";
            // console.log("### PICKER VALUE => '" + select.selectpicker("val") + "' type=" + (typeof select.selectpicker("val")));
            // console.log("### THIS VALUE => '" + value + "' type=" + (typeof value));
            // console.log("### PICKER VALUE => '" + pickerValue + "' type=" + (typeof pickerValue));
            if (value !== pickerValue && this.items.length > 0) {
                // console.log("## NOT SAME, SET IT!");
                select.selectpicker("val", this.value);
            }
        },
        disabled: function () {
            let select = $(this.$refs.select);
            select.prop('disabled', this.disabled);
            select.selectpicker("refresh");
        }
    },

    mounted() {
        let self = this;
        let select = $(this.$refs.select);
        select.prop('disabled', this.disabled);
        select.selectpicker();
        select.selectpicker("val", this.value);
        // eslint-disable-next-line
        select.on("changed.bs.select", function (e, clickedIndex, isSelected, previousValue) {
            //if (self.items && self.items.length > 0) {
                /*
            const val = select.selectpicker("val");
            if (!val && !clickedIndex) {
                self.$emit('update:value', null);
            } else if (clickedIndex) {
                self.$emit('update:value', self.items[clickedIndex]);
            }
*/
                self.$emit('update:value', select.selectpicker("val"));
                self.$emit('change');
            //}
        });
    },
    updated () {
        $(this.$refs.select).selectpicker('refresh')
    },
    destroyed() {
        $(this.$refs.select).selectpicker('destroy')
    },
}
</script>
