<template>
    <div class="card">
        <div class="card-header">
            <h3><i class="fa fa-taxi"></i>Enviar Bolt</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <form v-on:submit.prevent="makeRequest" id="prodUberForm" novalidate>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.externalRef.$error }">
                            <label for="externalRef" class="control-label">Referência Externa *</label>
                            <input class="form-control" type="text" id="externalRef"
                                   :class="{'is-invalid' : $v.requestData.externalRef.$error}"
                                   :disabled="loading || (showEstimate && !estimateExpired)"
                                   v-model="$v.requestData.externalRef.$model"/>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.externalRef.$error && !$v.requestData.externalRef.required">
                            {{ $t("root.required") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.externalRef.$error && !$v.requestData.externalRef.maxLength">
                            {{
                                    $t("root.generic_max_size", {max: $v.requestData.externalRef.$params.maxLength.max})
                                }}</span>
                        </div>

                        <div class="form-group">
                            <label for="firstName" class="control-label">{{ $t("ProductUseBolt.label.name") }} *</label>
                            <input class="form-control" type="text" id="firstName"
                                   :class="{'is-invalid' : $v.requestData.name.$error}"
                                   :disabled="loading || (showEstimate && !estimateExpired)"
                                   v-model="$v.requestData.name.$model"/>
                            <span class="invalid-feedback" v-show="!$v.requestData.name.required">
                        {{ $t("root.required") }}</span>
                            <span class="invalid-feedback" v-show="!$v.requestData.name.maxLength">
                        {{ $t("root.generic_max_size", {max: $v.requestData.name.$params.maxLength.max}) }}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.phone.$error }">
                            <label for="phone" class="control-label">{{ $t("ProductUseUber.telefone") }} *</label>
                            <input class="form-control" type="text" id="phone"
                                   :class="{'is-invalid' : $v.requestData.phone.$error}"
                                   :disabled="loading || (showEstimate && !estimateExpired)"
                                   v-model="$v.requestData.phone.$model"/>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.required">
                        {{ $t("root.required") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.numeric">
                        {{ $t("root.onlynumbers") }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.minLength">
                        {{ $t("root.generic_min_size", {min: $v.requestData.phone.$params.minLength.min}) }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.phone.$error && !$v.requestData.phone.maxLength">
                        {{ $t("root.generic_max_size", {max: $v.requestData.phone.$params.maxLength.max}) }}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.startAddress.$error }">
                            <label class="control-label">{{ $t("ProductUseUber.morada_partida") }} *</label>
                            <vue-google-autocomplete id="startAddress"
                                                     v-show="showStartAutocomplete"
                                                     ref="startadress"
                                                     :classname="startAddressClasses"
                                                     autocomplete="on"
                                                     placeholder=""
                                                     country="pt"
                                                     :disabled="loading || (showEstimate && !estimateExpired)"
                                                     :default-address="startAddressTmp"
                                                     @placechanged="getStartAddressData"
                                                     @change="updateStartAdress"
                                                     @keypress.enter.stop.prevent="doNothing"
                            >
                            </vue-google-autocomplete>
                            <div class="d-flex justify-content-end">
                                <button v-show="!showStartAutocomplete" class="btn btn-sm btn-link mr-2" @click.stop.prevent="showStartAutocomplete = true;">Alterar</button>
                                <input type="text" readonly class="form-control-plaintext" :value="requestData.startAddress">
                            </div>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.startAddress.$error && !$v.requestData.startAddress.maxLength">
                        {{
                                    $t("root.generic_max_size", {max: $v.requestData.startAddress.$params.maxLength.max})
                                }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.startAddress.$error && !$v.requestData.startAddress.required">
                        {{ $t("root.required") }}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.requestData.endAddress.$error }">
                            <label class="control-label">{{ $t("ProductUseUber.morada_chegada") }} *</label>
                            <!--<input class="form-control" type="text" id="endAddress"
                                   :class="{'is-invalid' : $v.requestData.endAddress.$error}"
                                   :disabled="showEstimate && validEstimate && !estimateExpired"
                                   v-model="$v.requestData.endAddress.$model"/>-->
                            <vue-google-autocomplete id="endAddress"
                                                     v-show="showEndAutocomplete"
                                                     :classname="endAddressClasses"
                                                     autocomplete="off"
                                                     placeholder=""
                                                     country="pt"
                                                     :disabled="loading || (showEstimate && !estimateExpired)"
                                                     :default-address="endAddressTmp"
                                                     @placechanged="getEndAddressData"
                                                     @change="updateEndAdress"
                                                     @keypress.enter.stop.prevent="doNothing"
                            >
                            </vue-google-autocomplete>
                            <div class="d-flex justify-content-end">
                                <button v-show="!showEndAutocomplete" class="btn btn-sm btn-link mr-2" @click.stop.prevent="showEndAutocomplete = true;">Alterar</button>
                                <input type="text" readonly class="form-control-plaintext" :value="requestData.endAddress">
                            </div>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.endAddress.$error && !$v.requestData.endAddress.maxLength">
                                {{
                                    $t("root.generic_max_size", {max: $v.requestData.endAddress.$params.maxLength.max})
                                }}</span>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.endAddress.$error && !$v.requestData.endAddress.required">
                                {{ $t("root.required") }}</span>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Quando *</label>
                            <select class="form-control"
                                    v-model="requestData.when"
                                    :disabled="loading || (showEstimate && !estimateExpired)"
                                    @change="scheduleChanged"
                            >
                                <option value="NOW">Agora</option>
                                <option value="FLEXIBLE">Flexível (Cliente solicita por SMS quando estiver pronto)
                                </option>
                                <option value="SCHEDULED">Agendar</option>
                            </select>
                        </div>
                        <div class="form-group" v-if="showDate"
                             :class="{ 'has-error': $v.requestData.date.$error }">
                            <label class="control-label">Data *</label>
                            <date-picker :value.sync="requestData.date"
                                         :start-date="startDate"
                                         :end-date="endDate"
                                         :disabled="loading || (showEstimate && !estimateExpired)"
                                         :class="{'is-invalid': $v.requestData.date.$error}"
                                         :error-class="{'is-invalid': $v.requestData.date.$error}"></date-picker>
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.date.$error && !$v.requestData.date.required">
                                {{ $t("root.required") }}</span>
                        </div>
                        <div v-if="showStartTime" class="form-group"
                             :class="{ 'has-error': $v.requestData.startTime.$error }">
                            <label class="control-label">Hora *</label>
                            <input type="hidden" v-model="requestData.credit" :class="{'is-invalid' : $v.requestData.startTime.$error}"/>
                            <b-select class="form-control"
                                      :items="times"
                                      :value.sync="requestData.startTime"
                                      :disabled="loading || (showEstimate && !estimateExpired)"
                                      labelfield="id"
                                      data-size="4"
                                      data-width="100%"
                                      data-none-selected-text="Nenhum Seleccionado"
                            >

                            </b-select>

<!--                            <select class="form-control"
                                    v-model="requestData.startTime"
                                    :disabled="loading || (showEstimate && !estimateExpired)"
                                    :class="{'is-invalid': $v.requestData.startTime.$error}"
                            >
                                <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
                            </select>-->
                            <span class="invalid-feedback"
                                  v-show="$v.requestData.startTime.$error && !$v.requestData.startTime.required">
                                {{ $t("root.required") }}</span>
                        </div>

                        <div v-if="showEstimate">
                            <div v-if="!estimateExpired" class="form-group row">
                                <label class="col-form-label col-sm-6">{{ $t("ProductUseUber.expira_em") }}</label>
                                <div class="col-sm-6">
                                    <input class="form-control-plaintext" type="text"
                                           :value="expiresIn + ' segundos'"
                                    />
                                </div>
                            </div>
                            <div v-if="estimateExpired" class="alert alert-danger">
                                Estimativa expirada! Tem que obter nova estimativa para enviar automóvel.
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12 d-flex mb-2 justify-content-end">
                                <div class="">
                                    <button v-if="showEstimate && !estimateExpired"
                                            @click.stop.prevent="dropEstimate"
                                            type="button"
                                            class="btn btn-warning mr-2">
                                        {{ $t("ProductUseUber.nova_estimativa_btn") }}
                                    </button>

                                    <v-button v-else
                                              type="submit"
                                              class="btn btn-primary zap-btn-rosa"
                                              :loading="loading"
                                    >
                                        <!--                                :disabled="!startAddressChanged || !endAddressChanged"  -->
                                        {{ $t("ProductUseUber.estimate_btn") }}
                                    </v-button>
                                </div>
                            </div>
                        </div>


                        <div v-if="showEstimate" class="list-group mt-1 mb-1">
                            <!--                            <hr/>-->

                            <div class="list-group-item">
                                <h3>Estimativas</h3>
                            </div>

                            <div class="list-group-item" v-for="(item, idx) in estimationsList" :key="item.fare_id">

                                <!--                                <p>{{ item.fare_id }}</p>-->

                                <div class="row">
                                    <label :for="'custoEstimado_' + idx"
                                           class="col-form-label col-sm-6">{{
                                            $t("ProductUseUber.custo_estimado")
                                        }}</label>
                                    <div class="col-sm-6">
                                        <input class="form-control-plaintext" type="text"
                                               :id="'custoEstimado_' + idx"
                                               :value="item.price.amount">
                                    </div>
                                </div>

                                <!--          v-if="validEstimate && requestData.when === 'NOW' && validEstimate.estimate.pickupEstimateInMinutes"                      -->
                                <div class="row">
                                    <label :for="'tempoEst_'+ idx"
                                           class="col-form-label col-sm-6">{{
                                            $t("ProductUseUber.tempo_estimado")
                                        }}</label>
                                    <div class="col-sm-6">
                                        <input class="form-control-plaintext" type="text"
                                               :id="'tempoEst_'+ idx"
                                               :value="getEtaMinutes(item.eta) + ' minutos'"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <label :for="'numerolugares' + idx"
                                           class="col-form-label col-sm-6">{{
                                            $t("ProductUseBolt.label.placesNum")
                                        }}</label>
                                    <div class="col-sm-6">
                                        <input class="form-control-plaintext" type="text"
                                               :id="'numerolugares' + idx"
                                               :value="item.seats">
                                    </div>
                                </div>

                                <div class="row">
                                    <label :for="'categoria' + idx"
                                           class="col-form-label col-sm-6">{{
                                            $t("ProductUseBolt.label.category")
                                        }}</label>
                                    <div class="col-sm-6">
                                        <input class="form-control-plaintext" type="text"
                                               :id="'categoria' + idx"
                                               :value="item.vehicle_category">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 d-flex justify-content-end">
                                        <v-button v-if="showEstimate && !estimateExpired"
                                                  @click.stop.prevent="makeRideRequest(item)"
                                                  class="btn btn-primary zap-btn-rosa" :loading="loading">
                                            {{ $t("ProductUseUber.request_btn") }}
                                        </v-button>
                                    </div>
                                </div>
                            </div>

                            <!--                            <hr/>-->
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

import VButton from "@/components/VButton";
import VueGoogleAutocomplete from "@/components/VueGoogleAutocomplete";
import moment from "moment";
import {getQueryParameterByName, showSuccessDialog} from "@/utils";
import DatePicker from "@/components/DatePicker";
import $ from "jquery";
// import jQuery from "jquery";
import toastr from "toastr";
import {i18n} from "@/main";
import BSelect from "@/components/BSelect";

let autocompleteService;
let geocoder;
if (window.google && window.google.maps) {
    autocompleteService = new window.google.maps.places.AutocompleteService();
    geocoder = new window.google.maps.Geocoder();
}

export default {
    components: {
        BSelect,
        DatePicker,
        VueGoogleAutocomplete,
        VButton,
    },
    mixins: [
    ],
    data() {
        return {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
            times: [],

            startAddressTmp: null,
            endAddressTmp: null,

            showStartAutocomplete: false,
            showEndAutocomplete: false,

            requestData: {
                auth: null,
                oid: null,
                externalRef: null,
                name: null,
                phone: null,
                startAddress: null,
                startLat: 0,
                startLng: 0,
                endAddress: null,
                endLat: 0,
                endLng: 0,
                when: 'NOW',
                date: null,
                startTime: null,
            },

            productUseId: null,
            estimationsList: [],


            startAddressChanged: false,
            endAddressChanged: false,

            showEstimate: false,
            expiresIn: 60,

            loading: false,

            intervalId: null,
        }
    },
    validations() {
        let validations = {
            requestData: {
                externalRef: {
                    required,
                    maxLength: maxLength(10),
                },

                name: {
                    required,
                    maxLength: maxLength(37)
                },
                phone: {
                    required,
                    numeric,
                    minLength: minLength(9),
                    maxLength: maxLength(9)
                },

                startAddress: {
                    required,
                    maxLength: maxLength(500),
                },
                endAddress: {
                    required,
                    maxLength: maxLength(500),
                },
                when: {
                    required,
                },
                date: {},
                startTime: {}
            }
        }

        if (this.showDate) {
            validations.requestData.date = {
                required,
            }
        }
        if (this.showStartTime) {
            validations.requestData.startTime = {
                required
            }
        }
        return validations;
    },
    mounted() {
        // http://localhost:9000/bolt-enviar?oid=2&token=sTekcuqpSQ8nPylALHgVVn6mpmhe4hhm&sid=123&n=gustavo%20santos&mp=rua%20dos%20anjos&t=983239288&mc=rua%20dos%20tanques&q=flexivel&d=2022-07-01&h=13:30
        this.requestData.date = moment().format('YYYY-MM-DD');
        this.requestData.startTime = "00:00";
        // this.updateTimes()
        // const self = this;

        /*
            https://ezap.jbaysolutions.com/bolt-enviar?auth=<token>&sid=<salesforce-id>&n=<nome>&t=<telefone>&mp=<morada-partida>&mc=<morada-chega>&q=<quando>&d=<data>&h=<hora>
            - auth - token de autenticação que iremos fornecer
            - sid - id do processo salesforce
            - n - nome
            - t - telefone
            - mp - morada de partida
            - mc - morada de chegada
            - q - quando (opções agora/flexivel/agendar)
            - d - data (apenas para opções flexivel/agendar, formato YYYY-MM-DD)
            - h - hora (apenas para opções flexivel/agendar, formato HH:MM)
         */
        this.requestData.oid = getQueryParameterByName("oid");
        this.requestData.auth = getQueryParameterByName("auth");
        this.requestData.externalRef = getQueryParameterByName("sid");
        this.requestData.name = getQueryParameterByName("n");
        this.requestData.phone = getQueryParameterByName("t");
        // console.log("start address tmp => " + getQueryParameterByName("mp"))
        this.startAddressTmp = getQueryParameterByName("mp");
        this.endAddressTmp = getQueryParameterByName("mc");
        // this.requestData.endAddress = getQueryParameterByName("mc");
        const quando = getQueryParameterByName("q");
        if (quando) {
            if (quando === "agora") {
                this.requestData.when = "NOW";
            } else if (quando === "flexivel") {
                this.requestData.when = "FLEXIBLE";
            } else {
                this.requestData.when = "SCHEDULED";
            }
        }
        this.requestData.date = getQueryParameterByName("d");
        if (this.requestData.date) {
            this.updateTimes();
            this.requestData.startTime = getQueryParameterByName("h");
        } else {
            this.requestData.date = moment().format('YYYY-MM-DD');
            this.updateTimes();
        }
        this.scheduleChanged();

        if (this.startAddressTmp) {
            this.findAddress(true);
        }
        if (this.endAddressTmp) {
            this.findAddress(false);
        }
        /*setTimeout(() => {
            var keyEvent = jQuery.Event("keydown");
            // console.log("event => " + keyEvent);
            //keyEvent.keyCode = 40; // down
            // keyEvent.keyCode = 65; // a
            keyEvent.keyCode = 13; // enter
            $("#startAddress").val('XX');
            $("#startAddress").focus();
            $("#startAddress").trigger(keyEvent);
            console.log("keydown")
            // document.getElementById("startAddress").dispatchEvent(new KeyboardEvent('keyup', {'key': 'Enter'}));
            // document.getElementById("startAddress").dispatchEvent(new KeyboardEvent('keyup', {'key': 'Enter'}));
        }, 2000);*/


    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.timesintervalId) {
            clearInterval(this.timesintervalId);
        }
    },
    watch: {
        "requestData.date"() {
            this.updateTimes();
        }
    },
    computed: {
        showDate() {
            return this.requestData.when === 'SCHEDULED' || this.requestData.when === 'FLEXIBLE'
        },
        showStartTime() {
            return this.requestData.when === 'SCHEDULED' || this.requestData.when === 'FLEXIBLE'
        },
        /*validEstimate() {
            if (this.estimateData.id) {
                if (this.when === 'SCHEDULED') {
                    let dateMoment = moment(this.requestData.date + " " + this.requestData.startTime);
                    if (dateMoment.isBefore(moment())) {
                        return false;
                    }
                }
                let validEstimates = this.estimateData.estimate.productEstimates.filter(est => {
                    return !est.noCarsAvailable
                });
                if (validEstimates) {
                    return validEstimates[0];
                }
            }
            return null;
        },*/
        estimateExpired() {
            return this.expiresIn < 0;
        },
        startAddressClasses() {
            let result = "form-control"
            if (this.$v.requestData.startAddress.$error) {
                result += " is-invalid";
            }
            return result;
        },
        endAddressClasses() {
            let result = "form-control"
            if (this.$v.requestData.endAddress.$error) {
                result += " is-invalid";
            }
            return result;
        }
    },
    methods: {
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        handleAjaxError(error, title) {
            if (error.response !== undefined) {
                if (error.response.data) {
                    if (!this.isJson(error.response.data)) {
                        if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
                            const errorEl = $(error.response.data).find("#error");
                            let message = ''
                            if (errorEl !== null && errorEl.length > 0)
                                message = $(error.response.data).find("#error").html();
                            else {
                                message = $(error.response.data).filter("title").text();
                            }
                            toastr.error(message, title ? title : i18n.tc('error.general_title'))
                        } else if (error.response.data) {
                            toastr.error(error.response.data, title ? title : i18n.tc('error.general_title'))
                        } else {
                            toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                        }
                    } else {
                        toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // if (error.response.data.msg) {
                        //     toastr.error(i18n.tc('error.general_description'), title ? title : i18n.tc('error.general_title'))
                        // }
                        console.log(JSON.stringify(error.response.data))
                    }
                } else {
                    toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.responseText !== undefined) {
                if (error.responseText.trim().length > 0) {
                    toastr.error(error.responseText, title ? title : i18n.tc('error.general_title'))
                } else {
                    toastr.error(error.statusText, title ? title : i18n.tc('error.general_title'))
                }
            } else if (error.message) {
                toastr.error(error.message, title ? title : i18n.tc('error.general_title'))
            } else {
                toastr.error(error, title ? title : i18n.tc('error.general_title'))
            }
        },
        // para ignorar o enter
        doNothing() {
        },
        scheduleChanged() {
            if (getQueryParameterByName("d") === undefined) {
                this.requestData.date = this.date;
            }
            this.updateTimes();
        },
        updateTimes() {
            //console.log("updateTimes")
            // const self = this;
            if (this.requestData.when === 'SCHEDULED' || this.requestData.when === 'FLEXIBLE') {
                this.times = [];
                let startMoment = moment(this.requestData.date);
                let dateIter = moment(this.requestData.date);
                while (startMoment.isSame(dateIter, 'day')) {
                    if (dateIter.isAfter(moment())) {
                        this.times.push({id: dateIter.format("HH:mm")})
                    }
                    dateIter.add(5, "minute");
                }
                this.requestData.startTime = null;
                /*const found = this.times.find(t => {
                    return self.requestData.startTime === t.id;
                })*/

                this.requestData.startTime = this.times[0].id;
            }
        },
        findAddress(isStart) {
            const self = this;

            //perform request. limit results to Portugal
            const request = {
                input: isStart ? this.startAddressTmp : this.endAddressTmp,
                componentRestrictions: {country: 'pt'},
            };
            autocompleteService.getPlacePredictions(request, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    if (predictions.length > 0) {
                        const pred = predictions[0];
                        if (pred.place_id) {
                            geocoder.geocode({placeId: pred.place_id}, (geocoderResult, placeStatus) => {
                                if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
                                    self.formatResult(isStart, geocoderResult[0]);
                                }
                            });
                        }
                    }
                }
            });
        },
        filterGeocodeResultTypes (results) {
            let output = [];
            let types = ["address"];

            for (let r of results) {
                for (let t of r.types) {
                    if (types.includes(t)) {
                        output.push(r);
                        break;
                    }
                }
            }
            return output;
        },
        updateStartAdress(text) {
            if (this.startAddressChanged) {
                this.requestData.startAddress = text;
                this.startAddressChanged = text.trim().length === 0;
            }
        },
        updateEndAdress(text) {
            if (this.endAddressChanged) {
                this.requestData.endAddress = text;
                this.endAddressChanged = text.trim().length === 0;
            }
        },

        formatResult(isStart, place) {
            if (isStart) {
                this.startAddressChanged = true;

                this.requestData.startAddress = place.formatted_address;
                this.requestData.startLat = place.geometry.location.lat();
                this.requestData.startLng = place.geometry.location.lng();
            } else {
                this.endAddressChanged = true;

                this.requestData.endAddress = place.formatted_address;
                this.requestData.endLat = place.geometry.location.lat();
                this.requestData.endLng = place.geometry.location.lng();
            }
        },

        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        getStartAddressData(addressData, placeResultData/*, id*/) {
            // console.log("StartAddressData - lat= %s - long= %s ", addressData.latitude, addressData.longitude); // lat= 38.7188808 - long= -9.292634699999999
            // console.log("addressData=" + JSON.stringify(addressData))
            // console.log("START placeResultData=" + JSON.stringify(placeResultData))
            // console.log("id=" + id)
            this.startAddressChanged = true;

            this.requestData.startAddress = placeResultData.formatted_address;
            this.requestData.startLat = addressData.latitude;
            this.requestData.startLng = addressData.longitude;
        },
        getEndAddressData(addressData, placeResultData/*, id*/) {
            // console.log("EndAddressData - lat= %s - long= %s ", addressData.latitude, addressData.longitude); // lat= 38.6922357 - long= -9.3254077
            // console.log("addressData=" + JSON.stringify(addressData))
            // console.log("END placeResultData=" + JSON.stringify(placeResultData))
            // console.log("id=" + id)
            this.endAddressChanged = true;
            this.requestData.endAddress = placeResultData.formatted_address;
            this.requestData.endLat = addressData.latitude;
            this.requestData.endLng = addressData.longitude;
        },
        startUpdatingExpires() {
            const self = this;
            this.expiresIn = 60;
            this.intervalId = setInterval(() => {
                self.updateEstimateExpires();
            }, 1 * 1000); // 1 segundo
        },
        updateEstimateExpires() {
            this.expiresIn = this.expiresIn - 1;
            if (this.expiresIn <= 0) {
                clearInterval(this.intervalId);
            }
        },
        makeRequest() {
            if (this.showEstimate && this.validEstimate && !this.estimateExpired) {
                this.makeRideRequest();
            } else {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    console.log("### INVALID");
                    return;
                }
                this.makeRideEstimate();
            }
        },


        /**
         *
         * routes.javascript.ProductController.boltRideEstimate(),
         * routes.javascript.ProductController.boltRideRequest(),
         *
         */

        dropEstimate() {
            this.showEstimate = false;
        },
        makeRideRequest(ride) {
            const self = this;
            self.loading = true;
            //console.log("makeRideRequest => " + JSON.stringify(ride))

            //{"fare_id":"AAACujE2MzU5Mzc0NTY3MTcwMTktM2xBUlRRaFM3OHk4ZXY3VQ","vehicle_category":"standard","seats":4,"price":{"amount":"2.69€","minimum_amount":2.69,"maximum_amount":2.69,"currency_code":"EUR"},"eta":240}

            self.loading = false;
            self.showEstimate = false;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ProductBoltController.boltRideRequest().url;

            let dataObj;
            if (this.requestData.when === "NOW") {
                dataObj = {
                    oid: this.requestData.oid,
                    auth: this.requestData.auth,
                    id: this.productUseId,
                    fareId: ride.fare_id
                }
            } else {
                dataObj = {
                    oid: this.requestData.oid,
                    auth: this.requestData.auth,
                    id: this.productUseId,
                    vehicleCategoryId: ride.vehicle_category_id
                }
            }

            axios.post(url, dataObj).then(function (/*response*/) {
                // set data
                self.loading = false;
                showSuccessDialog("Sucesso", "Automóvel enviado!");
            }).catch(function (error) {
                self.loading = false
                // handle error
                self.handleAjaxError(error);
            });
        },

        makeRideEstimate() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                // console.log("### INVALID");
                return;
            }
            const self = this;
            self.loading = true;
            self.showEstimate = false;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ProductBoltController.boltRideEstimate().url;

            axios.post(url, this.requestData)
                .then(function (response) {
                    // set data
                    self.expiresIn = 60;
                    self.showEstimate = true;
                    // console.debug('response de estimativa: ', response)
                    self.productUseId = response.data.id;
                    self.estimationsList = response.data.estimate;
                    self.startUpdatingExpires();

                })
                .catch(function (error) {
                    self.showEstimate = false;
                    self.handleAjaxError(error);
                    throw error;
                })
                .finally(() => {
                    self.loading = false;
                });
        },

        getEtaMinutes(seconds) {
            if (!seconds) {
                return '---'
            }
            return seconds / 60;
        }
    },
}
</script>

<style scoped>

</style>
