<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="sidebar-brand zap-bg-rosa" style="text-indent: unset;">
                <router-link to='/'>
                    <img src="/assets/images/ezap-neg.png" class="img-fluid">
                </router-link>
            </li>
            <!--<li :class="{ 'active': $route.name === 'Home' }">
                <router-link to="/"><i class="fa fa-home"></i> {{$t("mainmenu.home")}}</router-link>
            </li>-->
            <li :class="{ 'active': $route.meta.section === 'products' }">
                <router-link :to="{name: 'ProductList'}"><i class="fa fa-shopping-cart"></i> {{$t("mainmenu.products")}}</router-link>
            </li>
            <li v-if="isAdmin" :class="{ 'active': $route.meta.section === 'users' }">
                <router-link :to="{name: 'Users'}"><i class="fa fa-users-cog"></i> {{$t("mainmenu.users")}}</router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'alarms' }">
                <router-link :to="{name: 'AlarmPendingList'}">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{$t("mainmenu.alarms")}}
                    <span v-show="alarmsNotClosedCount > 0"
                          class="badge badge-pill badge-danger"
                          style="text-indent: 0;"
                    >
                        {{alarmsNotClosedCount}}
                    </span>
                </router-link>
            </li>
            <li v-if="isAdmin" :class="{ 'active': $route.meta.section === 'kpis' }">
                <router-link :to="{name: 'Kpis'}"><i class="fa fa-chart-line"></i> {{$t("mainmenu.kpis")}}</router-link>
            </li>
            <li :class="{ 'active': $route.meta.section === 'billing' }">
                <router-link :to="{name: 'Billing'}">
                    <i class="fa fa-dollar-sign"></i>
                    {{$t("mainmenu.billing")}}
                </router-link>
            </li>
            <!--<li :class="{ 'active': $route.name === 'About' }">
                <router-link to="/about"><i class="fa fa-user"></i> {{$t("mainmenu.about")}}</router-link>
            </li>-->
        </ul>
    </div>
</template>
<style scoped>
    li > a > i {
        width:30px;
        text-align: center;
    }
</style>
<script>

    // import {getLanguageOptions} from "../../language-utils";
    // import {i18n} from "../../main"
    import {mapGetters} from "vuex";

    export default {
        components: {
        },
        data () {
            return {
                // selectedMenu: '',
                // selected: i18n.locale,
                // languageOptions: getLanguageOptions(),

                // showAgencyMenu: false
            }
        },
        watch: {
        },
        mounted () {
            // ambas estas vars devem estar no main.scala.html
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters({
                profile: 'profile',
                alarmsNotClosedCount: 'alarmsNotClosedCount',
                isAdmin: 'isAdmin'
            }),
        },
        methods: {
        }
    }
</script>
