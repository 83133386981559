<template>
    <div>
        <nav class="sidebar-brand zap-bg-rosa d-flex justify-content-between align-items-center">
            <div style="width:150px;">
                <img src="/assets/images/ezap-neg.png" class="img-fluid">
            </div>
            <h2 v-if="requestData.status === 'CLOSED'" class="mr-2">
                <span class="badge badge-success">{{requestData.statusDesc}}</span>
            </h2>
        </nav>
        <div class="container-fluid mb-3">
            <div class="row">
                <div class="col-md-8 col-sm-12 mx-auto">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3>Processo {{uuid}}</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <label for="data" class="col-sm-3 col-form-label">Data</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="data" :value="requestData.date">
                                </div>
                            </div>
                            <!--                        <div class="form-group row">
                                                        <label for="ref" class="col-sm-3 col-form-label">Referência Externa</label>
                                                        <div class="col-sm-9">
                                                            <span class="form-control-plaintext" id="ref">{{requestData.externalRef}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="nome" class="col-sm-3 col-form-label">Nome</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" readonly class="form-control-plaintext" id="nome" :value="requestData.name">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="telefone" class="col-sm-3 col-form-label">Telefone</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" readonly class="form-control-plaintext" id="telefone" :value="requestData.phone">
                                                        </div>
                                                    </div>-->
                            <div class="form-group row">
                                <label for="marca" class="col-sm-3 col-form-label">Marca</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="marca" :value="requestData.vBrand">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="modelo" class="col-sm-3 col-form-label">Modelo</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="modelo" :value="requestData.vModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="matricula" class="col-sm-3 col-form-label">Marca</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="matricula" :value="requestData.vPlate">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="peritagem" class="col-sm-3 col-form-label">Oficina</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="peritagem">{{requestData.oficinaNome}}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="telefone" class="col-sm-3 col-form-label">Oficina - Telefone</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="telefone">{{requestData.oficinaTelefone}}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-sm-3 col-form-label">Oficina - Email</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="email">{{requestData.oficinaEmail}}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="morada" class="col-sm-3 col-form-label">Oficina - Morada</label>
                                <div class="col-sm-9">
                                <span class="form-control-plaintext" id="morada">
<!--                                    <a href="#" @click.stop.prevent="showMapModal = true">
                                        {{requestData.oficinaMorada + ", " + requestData.oficinaCodigoPostal + " " + requestData.oficinaLocalidade}}
                                    </a>-->
                                    <a :href="mapsUrl" target="_blank" v-tooltip="'Abrir mapa'">
                                        {{requestData.oficinaMorada + ", " + requestData.oficinaCodigoPostal + " " + requestData.oficinaLocalidade}}
                                        <i class="fa fa-external-link"></i>
                                    </a>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2 mb-3">
                        <div class="card-header">
                            <h3>
                                Histórico

                            </h3>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" v-for="history in requestData.historyList" :key="history.id">
                                <div class="row mb-2">
                                    <div class="col-sm-3 text-md-right font-weight-bold">Data</div>
                                    <div class="col-sm-9">{{history.date}}</div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-3 text-md-right font-weight-bold">Utilizador</div>
                                    <div class="col-sm-9">{{history.userName ? history.userName : 'Oficina'}}</div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-3 text-md-right font-weight-bold">Estado</div>
                                    <div class="col-sm-9">{{history.details.subStatusDesc ? history.details.subStatusDesc : history.statusDesc}}</div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-sm-3 text-md-right font-weight-bold">Detalhes</div>
                                    <div class="col-sm-9">{{history.details.description}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <oficina-map-modal
                :show.sync="showMapModal"
                :request-data="requestData"
            ></oficina-map-modal>
        </div>
    </div>
</template>

<style lang="css">
.col-form-label {
    font-weight: bold;
}
</style>
<script>

import axios from "axios";
import {ajaxErrorNoAuthMixin} from "@/mixins";
import VueTextareaAutosize from "vue-textarea-autosize";
import Vue from 'vue';
import OficinaMapModal from "@/components/product/networks/OficinaMapModal";

Vue.use(VueTextareaAutosize);

    export default {
        mixins: [
            ajaxErrorNoAuthMixin,
        ],
        components: {
            OficinaMapModal,
        },
        data () {
            return {
                uuid: null,

                requestData: {
                    date: null,
                    status: null,
                    statusDesc: null,

                    oficinaId: null,
                    oficinaNome: null,
                    oficinaMorada: null,
                    oficinaLocalidade: null,
                    oficinaCodigoPostal: null,
                    oficinaLat: null,
                    oficinaLon: null,
                    oficinaTelefone: null,
                    oficinaEmail: null,
                    vBrand: null,
                    vModel: null,
                    vPlate: null,
                },

                showMapModal: false
            }
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.uuid = urlParams.get('uuid');
            document.title = "Processo - E-Zap";
            this.loadData();
        },
        computed: {
            mapsUrl() {
                // return "https://www.google.com/maps/search/?api=1&query=" + this.requestData.oficinaLat + "," + this.requestData.oficinaLon;
                return "https://www.google.com/maps/dir/?api=1&destination=" + this.requestData.oficinaLat + "," + this.requestData.oficinaLon
            }
        },
        methods: {
            loadData() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductNetworksClienteController.loadProcessDetail(this.uuid).url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.requestData = response.data;
                }).catch(function (error) {
                    // handle error
                    self.handleAjaxError(error);
                });
            },
        }
    }
</script>
