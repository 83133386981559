<template>
    <div>
        <nav class="sidebar-brand zap-bg-rosa d-flex justify-content-between align-items-center">
            <div style="width:150px;">
                <img src="/assets/images/ezap-neg.png" class="img-fluid">
            </div>
            <h2 v-if="requestData.status === 'CLOSED'" class="mr-2">
                <span class="badge badge-success">{{requestData.statusDesc}}</span>
            </h2>
        </nav>
        <div class="container-fluid mb-3">
            <div class="row">
                <div class="col-md-8 col-sm-12 mx-auto">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3>Processo {{uuid}}</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row">
                                <label for="data" class="col-sm-3 col-form-label">Data</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="data" :value="requestData.date">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ref" class="col-sm-3 col-form-label">Referência Externa</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="ref">{{requestData.externalRef}}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nome" class="col-sm-3 col-form-label">Nome</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="nome" :value="requestData.name">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="telefone" class="col-sm-3 col-form-label">Telefone</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="telefone" :value="requestData.phone">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="marca" class="col-sm-3 col-form-label">Marca</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="marca" :value="requestData.vBrand">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="modelo" class="col-sm-3 col-form-label">Modelo</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="modelo" :value="requestData.vModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="matricula" class="col-sm-3 col-form-label">Marca</label>
                                <div class="col-sm-9">
                                    <input type="text" readonly class="form-control-plaintext" id="matricula" :value="requestData.vPlate">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="peritagem" class="col-sm-3 col-form-label">Peritagem</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="peritagem">{{requestData.peritagemTipo === 'DEFINITIVA' ? "Definitiva" : "Condicional"}}</span>
                                </div>
                            </div>
                            <div class="form-group row" v-if="requestData.peritagemData">
                                <label for="peritagemData" class="col-sm-3 col-form-label">Peritagem - Data</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="peritagemData">{{requestData.peritagemTipo === 'DEFINITIVA' ? "Definitiva" : "Condicional"}}</span>
                                </div>
                            </div>
                            <div class="form-group row" v-if="requestData.peritagemPeriodoLabel">
                                <label for="peritagemPeriodo" class="col-sm-3 col-form-label">Peritagem - Período</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="peritagemPeriodo">{{requestData.peritagemPeriodoLabel}}</span>
                                </div>
                            </div>
                            <div class="form-group row" v-if="requestData.obs">
                                <label for="obs" class="col-sm-3 col-form-label">Observações</label>
                                <div class="col-sm-9">
                                    <span class="form-control-plaintext" id="obs">{{requestData.obs}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-2" v-if="requestData.status === 'PENDING'">
                        <div class="card-header">
                            <h3>Actualizar Processo</h3>
                        </div>
                        <div class="card-body">
                            <form v-on:submit.prevent="updateStatus" id="oscarReplyForm" novalidate>
                                <div class="form-group row"  :class="{ 'has-error': $v.updateData.subStatus.$error }">
                                    <label for="subStatus" class="col-sm-3 col-form-label">Estado *</label>
                                    <div class="col-sm-9">
    <!--                                    <input class="form-control" type="text" id="subStatus"
                                               :class="{'is-invalid' : $v.updateData.subStatus.$error}"
                                               v-model="$v.updateData.subStatus.$model"/>-->
                                        <select id="subStatus"
                                                class="form-control"
                                                :class="{'is-invalid' : $v.updateData.subStatus.$error}"
                                                v-model="$v.updateData.subStatus.$model">
                                            <option value="CONFIRMATION_SERVICE_AGENDA">Confirmação</option>
                                            <option value="CONCLUSION_LOGISTICS_PROCESS">Peças</option>
                                            <option value="REPAIR_START">Reparação Iniciada</option>
                                            <option value="REPAIR_FINISH">Reparação Terminada</option>
                                        </select>
                                        <span class="invalid-feedback" v-show="$v.updateData.subStatus.$error && !$v.updateData.subStatus.required">
                                        {{$t("root.required")}}</span>
                                    </div>
                                </div>
                                <div class="form-group row"  :class="{ 'has-error': $v.updateData.description.$error }">
                                    <label class="col-sm-3 col-form-label">Descrição</label>
                                    <div class="col-sm-9">
                                        <textarea-autosize class="form-control"
                                                           v-model="updateData.description"
                                                           :class="{'is-invalid' : $v.updateData.description.$error}"
                                                           :min-height="80"
                                                           :max-height="300">
                                        </textarea-autosize>
                                        <span class="invalid-feedback" v-show="$v.updateData.description.$error && !$v.updateData.description.required">
                                        {{$t("root.required")}}</span>
                                    </div>
                                </div>

                                <div class="float-right">
                                    <v-button type="submit" class="btn btn-primary zap-btn-rosa" :loading="saving">Actualizar</v-button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card mt-2 mb-3">
                        <div class="card-header">
                            <h3>
                                Histórico

                            </h3>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" v-for="history in requestData.historyList" :key="history.id">
                                <div class="row">
                                    <div class="col-3 text-right font-weight-bold">Data</div>
                                    <div class="col-6">{{history.date}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-3 text-right font-weight-bold">Utilizador</div>
                                    <div class="col-6">{{history.userName ? history.userName : 'Oficina'}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-3 text-right font-weight-bold">Estado</div>
                                    <div class="col-6">{{history.details.subStatusDesc ? history.details.subStatusDesc : history.statusDesc}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-3 text-right font-weight-bold">Detalhes</div>
                                    <div class="col-6">{{history.details.description}}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>
<script>

    import {required} from "vuelidate/lib/validators";
    import axios from "axios";
    import {ajaxErrorNoAuthMixin} from "@/mixins";
    import VButton from "../components/VButton";
    import VueTextareaAutosize from "vue-textarea-autosize";
    import Vue from 'vue';
    import {showSuccessDialog} from "@/utils";
    Vue.use(VueTextareaAutosize);

    export default {
        mixins: [
            ajaxErrorNoAuthMixin,
        ],
        components: {
            VButton
        },
        data () {
            return {
                uuid: null,

                requestData: {
                    date: null,
                    exernalRef: null,
                    name: null,
                    phone: null,
                    vBrand: null,
                    vModel: null,
                    vPlate: null,
                    peritagemTipo: null,
                    peritagemData: null,
                    peritagemPeriodo: null,
                    peritagemPeriodoLabel: null,
                    obs: null,
                    status: null,
                    subStatus: null,
                    subStatusDesc: null,
                },

                updateData: {
                    uuid: null,
                    subStatus: null,
                    description: null
                },

                saving: false,
            }
        },
        validations() {
            return {
                updateData: {
                    subStatus: {
                        required,
                    },
                    description: {
                    },
                },
            }
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.uuid = urlParams.get('uuid');
            document.title = "Processo - E-Zap";
            this.loadData();
        },
        computed: {
        },
        methods: {
            loadData() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductNetworksOficinaController.loadProcessDetail(this.uuid).url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.requestData = response.data;
                    self.updateData.subStatus = self.requestData.subStatus;
                    self.updateData.uuid = self.uuid;
                }).catch(function (error) {
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            updateStatus() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                const self = this;
                self.saving = true;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductNetworksOficinaController.doUpdate().url;

                axios.post(url, this.updateData).then(function (/*response*/) {
                    // set data
                    self.saving = false;
                    self.updateData.description = null;
                    self.$v.$reset();
                    // TODO msgs
                    showSuccessDialog("Sucesso", "Actualização registada. Obrigado!")
                    self.loadData();
                }).catch(function (error) {
                    self.saving = false
                    // handle error
                    self.handleAjaxError(error);
                });
            }
        }
    }
</script>
