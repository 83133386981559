import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
/*
    {
        path: "/",
        name: "Home",
        component: Home
    },
*/
    {
        path: "/change-password",
        name: "ChangePassword",
        component: () =>
            import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue")
    },
    {
        path: "/user-profile",
        name: "UserProfile",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
    },
    {
        path: "/users",
        name: "Users",
        meta: {section: 'users'},
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/users/UserList.vue")
    },
    {
        path: "/user-invite",
        name: "UserInvite",
        meta: { section: 'users'},
        component: () =>
            import(/* webpackChunkName: "users" */ "../views/users/UserInvite.vue")
    },
    {
        // path: "/products",
        path: "/",
        name: "ProductList",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/products/ProductList.vue")
    },
    {
        path: "/products/pending",
        name: "ProductPendingList",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/products/ProductPendingList.vue")
    },
    {
        path: "/products/history",
        name: "ProductHistoryList",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/products/ProductHistoryList.vue")
    },
    {
        path: "/product/:opid/use",
        name: "ProductUse",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/products/ProductUse.vue")
    },
    {
        path: "/product/use-detail/:id",
        name: "ProductUseDetail",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/products/detail/ProductUseDetail.vue")
    },
    {
        path: "/kpis",
        name: "Kpis",
        meta: { section: 'kpis'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "kpis" */ "../views/kpis/Kpis.vue")
    },
    {
        path: "/billing",
        name: "Billing",
        meta: { section: 'billing'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "billing" */ "../views/billing/Billing.vue")
    },
    {
        path: "/alarms",
        name: "AlarmPendingList",
        meta: { section: 'alarms'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "alarms" */ "../views/alarms/AlarmPendingList.vue")
    },
    {
        path: "/alarms/history",
        name: "AlarmHistoryList",
        meta: { section: 'alarms'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "alarms" */ "../views/alarms/AlarmHistoryList.vue")
    },
    {
        path: "/alarm/:id",
        name: "AlarmDetail",
        meta: { section: 'alarms'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "alarms" */ "../views/alarms/AlarmDetail.vue")
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    }
];

const router = new VueRouter({
    routes
});

export default router;
