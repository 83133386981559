<template>
    <span v-tooltip="formattedDate">{{fromNow}}</span>
</template>

<script>
    import moment from 'moment';
    moment.locale('pt')

    export default {
        props: {
            val: {type: [Number, String], default: null},
        },
        data() {
            return {
                intervalId: null,
                fromNow: "",
                formattedDate: "",

                dateFormat: "YYYY-MM-DD HH:mm:ss"
            }
        },
        mounted() {
            const self = this;
            this.refreshNow();
            this.intervalId = setInterval(() => {
                self.refreshNow();
            }, 1 * 1000); // 1 segundo
        },
        beforeDestroy() {
            clearInterval(this.intervalId);
        },
        methods: {
            refreshNow() {
                if (this.val) {
                    // eslint-disable-next-line
                    if (isNaN(this.val)) {
                        this.fromNow = moment(this.val, this.dateFormat).fromNow();
                    } else {
                        this.fromNow = moment(this.val).fromNow();
                    }
                    this.formattedDate = moment(this.val).format(this.dateFormat);
                    // this.fromNow = Math.floor(this.val - new Date() / 1000);
                } else {
                    return "";
                }
            }
        }
    }
</script>

<style scoped>

</style>
