<template>
    <div class="container-fluid mb-3">
        <div class="row">
            <div class="col-md-8 col-sm-12 mx-auto">
                <div class="card mt-2">
                    <div class="card-header">
                        <!-- TODO MENSAGENS? -->
                        <h2>Pedido de dados financeiros</h2>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <label for="uid" class="col-sm-3 col-form-label">ID</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="uid" :value="uuid">
                            </div>
                        </div>
                        <div class="row">
                            <label for="data" class="col-sm-3 col-form-label">Data</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="data" :value="requestData.data">
                            </div>
                        </div>
                        <div class="row">
                            <label for="nome" class="col-sm-3 col-form-label">Nome</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="nome" :value="requestData.nome">
                            </div>
                        </div>
                        <div class="row">
                            <label for="telefone" class="col-sm-3 col-form-label">Telefone</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="telefone" :value="requestData.telefone">
                            </div>
                        </div>
                        <div class="row">
                            <label for="morada" class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control-plaintext" id="morada" :value="requestData.email">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2" v-if="personRequestSs || companyRequestSs">
                    <div class="card-header">
                        <h3>Segurança Social Directa</h3>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="sendSs" novalidate>
                            <div class="form-group">
                                <label>Tipo de autenticação</label>
                                <select class="form-control" v-model="segSocialData.tipoLogin" disabled>
                                    <option value="NISS">Utilizador e palavra-chave</option>
                                    <option value="CHAVE_MOVEL">Chave móvel digital</option>
                                </select>
                            </div>
                            <div v-if="segSocialData.tipoLogin === 'NISS'">
                                <div class="form-group"  :class="{ 'has-error': $v.segSocialData.user.$error }">
                                    <label for="numSs">Utilizador da Segurança Social *</label>
                                    <input class="form-control" type="text" id="numSs"
                                           :class="{'is-invalid' : $v.segSocialData.user.$error}"
                                           v-model="$v.segSocialData.user.$model"/>
                                    <span class="invalid-feedback" v-show="$v.segSocialData.user.$error && !$v.segSocialData.user.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group"  :class="{ 'has-error': $v.segSocialData.password.$error }">
                                    <label for="passwordss">Palavra-chave *</label>
                                    <input class="form-control" type="password" id="passwordss"
                                           :class="{'is-invalid' : $v.segSocialData.password.$error}"
                                           v-model="$v.segSocialData.password.$model"/>
                                    <span class="invalid-feedback" v-show="$v.segSocialData.password.$error && !$v.segSocialData.password.required">
                                        {{$t("root.required")}}</span>
                                </div>
                            </div>
                            <div v-else>
                                <div class="form-group"  :class="{ 'has-error': $v.segSocialData.telefone.$error }">
                                    <label for="telemovel">Telemóvel *</label>
                                    <input class="form-control" type="text" id="telemovel"
                                           :class="{'is-invalid' : $v.segSocialData.telefone.$error}"
                                           v-model="$v.segSocialData.telefone.$model"/>
                                    <span class="invalid-feedback" v-show="$v.segSocialData.telefone.$error && !$v.segSocialData.telefone.required">
                                {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group"  :class="{ 'has-error': $v.segSocialData.pin.$error }">
                                    <label for="pinss">PIN *</label>
                                    <input class="form-control" type="password" id="pinss"
                                           :class="{'is-invalid' : $v.segSocialData.pin.$error}"
                                           v-model="$v.segSocialData.pin.$model"/>
                                    <span class="invalid-feedback" v-show="$v.segSocialData.pin.$error && !$v.segSocialData.pin.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div v-if="ssAskSms" class="form-group" :class="{ 'has-error': ssShowSmsError }">
                                    <label for="smsCode">Código SMS *</label>
                                    <div class="d-flex">
                                        <input class="form-control" type="password" id="smsCode"
                                               :class="{'is-invalid' : ssShowSmsError}"
                                               v-model="ssCodigoSms"/>
                                        <v-button class="ml-2 btn btn-primary zap-btn-rosa" style="width:200px;" @click.native="sendCodigoSms">Enviar Código SMS</v-button>
                                    </div>
                                    <span class="invalid-feedback" v-show="ssShowSmsError">
                                        {{$t("root.required")}}</span>
                                </div>
                            </div>

                            <div class="float-right">
                                <v-button type="submit" class="btn btn-primary zap-btn-rosa" :loading="sendingSs">Enviar</v-button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card mt-2" v-if="personRequestPortalFinancas || companyRequestPortalFinancas">
                    <div class="card-header">
                        <h3>Portal das finanças</h3>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="sendFinancas" novalidate>
                            <div class="form-group">
                                <label>Tipo de autenticação</label>
                                <select class="form-control" v-model="financasData.tipoLogin" disabled>
                                    <option value="NIF">NIF e palavra-chave</option>
                                    <option value="CHAVE_MOVEL">Chave móvel digital</option>
                                </select>
                            </div>
                            <div v-if="financasData.tipoLogin === 'NIF'">
                                <div class="form-group"  :class="{ 'has-error': $v.financasData.user.$error }">
                                    <label for="nif">NIF *</label>
                                    <input class="form-control" type="text" id="nif"
                                           :class="{'is-invalid' : $v.financasData.user.$error}"
                                           v-model="$v.financasData.user.$model"/>
                                    <span class="invalid-feedback" v-show="$v.financasData.user.$error && !$v.financasData.user.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group"  :class="{ 'has-error': $v.financasData.password.$error }">
                                    <label for="passwordfinancas">Palavra-chave *</label>
                                    <input class="form-control" type="password" id="passwordfinancas"
                                           :class="{'is-invalid' : $v.financasData.password.$error}"
                                           v-model="$v.financasData.password.$model"/>
                                    <span class="invalid-feedback" v-show="$v.financasData.password.$error && !$v.financasData.password.required">
                                        {{$t("root.required")}}</span>
                                </div>
                            </div>
                            <div v-else>
                                <div class="form-group"  :class="{ 'has-error': $v.financasData.telefone.$error }">
                                    <label for="telemovelfinancas">Telemóvel *</label>
                                    <input class="form-control" type="text" id="telemovelfinancas"
                                           :class="{'is-invalid' : $v.financasData.telefone.$error}"
                                           v-model="$v.financasData.telefone.$model"/>
                                    <span class="invalid-feedback" v-show="$v.financasData.telefone.$error && !$v.financasData.telefone.required">
                                {{$t("root.required")}}</span>
                                </div>
                                <div class="form-group"  :class="{ 'has-error': $v.financasData.pin.$error }">
                                    <label for="pinfinancas">PIN *</label>
                                    <input class="form-control" type="password" id="pinfinancas"
                                           :class="{'is-invalid' : $v.financasData.pin.$error}"
                                           v-model="$v.financasData.pin.$model"/>
                                    <span class="invalid-feedback" v-show="$v.financasData.pin.$error && !$v.financasData.pin.required">
                                        {{$t("root.required")}}</span>
                                </div>
                                <div v-if="financasAskSms" class="form-group" :class="{ 'has-error': financasShowSmsError }">
                                    <label for="smsCode2">Código SMS *</label>
                                    <div class="d-flex">
                                        <input class="form-control" type="password" id="smsCode2"
                                               :class="{'is-invalid' : financasShowSmsError}"
                                               v-model="financasCodigoSms"/>
                                        <v-button class="ml-2 btn btn-primary zap-btn-rosa" style="width:200px;" @click.native="sendCodigoSms">Enviar Código SMS</v-button>
                                    </div>
                                    <span class="invalid-feedback" v-show="financasShowSmsError">
                                        {{$t("root.required")}}</span>
                                </div>
                            </div>
                            <div class="float-right">
                                <v-button type="submit" class="btn btn-primary zap-btn-rosa" :loading="sendingFinancas">Enviar</v-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
</style>
<script>

    import {required} from "vuelidate/lib/validators";
    import axios from "axios";
    import {ajaxErrorNoAuthMixin} from "@/mixins";
    import VButton from "../components/VButton";
    import VueTextareaAutosize from "vue-textarea-autosize";
    import Vue from 'vue';
    import {showSuccessDialog} from "@/utils";
    Vue.use(VueTextareaAutosize);

    export default {
        mixins: [
            ajaxErrorNoAuthMixin,
        ],
        components: {
            VButton
        },
        data () {
            return {
                uuid: null,

                requestData: {
                    nome: null,
                    telefone: null,
                    email: null,
                    tipoDados: [],
                    // tipoDadosExistentes: [],
                },

                segSocialData: {
                    tipoLogin: 'NISS',
                    user: null,
                    password: null,
                    telefone: null,
                    pin: null,
                },
                ssCodigoSms: null,
                ssAskSms: false,
                ssShowSmsError: false,

                financasData: {
                    tipoLogin: 'NIF',
                    user: null,
                    password: null,
                    telefone: null,
                    pin: null,
                },
                financasCodigoSms: null,
                financasAskSms: false,
                financasShowSmsError: false,

                sendingSs: false,
                sendingFinancas: false,
            }
        },
        validations() {
            let validations = {}
            if (this.segSocialData.tipoLogin === 'NISS') {
                validations.segSocialData = {
                    tipoLogin: {},
                    user: {
                        required,
                    },
                    password: {
                        required
                    },
                    telefone: {},
                    pin: {},
                }
            } else {
                validations.segSocialData = {
                    tipoLogin: {},
                    user: {},
                    password: {},
                    telefone: {
                        required
                    },
                    pin: {
                        required
                    },
                }
            }
            if (this.financasData.tipoLogin === 'NIF') {
                validations.financasData = {
                    tipoLogin: {},
                    user: {
                        required,
                    },
                    password: {
                        required
                    },
                    telefone: {},
                    pin: {},
                }
            } else {
                validations.financasData = {
                    tipoLogin: {},
                    user: {},
                    password: {},
                    telefone: {
                        required
                    },
                    pin: {
                        required
                    },
                }
            }
            return validations;
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.uuid = urlParams.get('uuid');
            document.title = "Pedido de dados financeiros";
            this.loadData();
        },
        computed: {
            personRequestSs() {
                return this.requestData.tipoDados.includes("LATEST_PAYMENTS") ||
                    this.requestData.tipoDados.includes("PERSONAL_DATA");
            },
            /*personRequestSs() {
                return (this.requestData.tipoDados.includes("LATEST_PAYMENTS") && !this.requestData.tipoDadosExistentes.includes("LATEST_PAYMENTS")) ||
                    (this.requestData.tipoDados.includes("PERSONAL_DATA") && !this.requestData.tipoDadosExistentes.includes("LATEST_PAYMENTS"));
            },*/
            personRequestPortalFinancas() {
                return this.requestData.tipoDados.includes("PERSONAL_TAX_DATA") ||
                    this.requestData.tipoDados.includes("LAST_TAX_DECLARATION") ||
                    this.requestData.tipoDados.includes("TAX_ADDRESS_CERT") ||
                    this.requestData.tipoDados.includes("NON_DEBT_CERT") ||
                    this.requestData.tipoDados.includes("CENTRAL_CREDIT_REGISTER");
            },
            companyRequestPortalFinancas() {
                return this.requestData.tipoDados.includes("COMPANY_TAX_DATA") ||
                    this.requestData.tipoDados.includes("LAST_IRC_DEC") ||
                    this.requestData.tipoDados.includes("COMPANY_FISCAL_SITUATION");
            },
            companyRequestSs() {
                return this.requestData.tipoDados.includes("COMPANY_LAST_MONTH_INFO");
            }
        },
        methods: {
            loadData() {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyHapiController.getHapiRequestDetail(this.uuid).url;

                // GET request
                axios.get(url).then(function (response) {
                    // set data
                    self.requestData = response.data;
                    self.segSocialData.telefone = self.requestData.telefone;
                }).catch(function (error) {
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            sendSs() {
                this.$v.segSocialData.$touch();
                if (this.$v.segSocialData.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                const self = this;
                self.sendingSs = true;
                if (this.segSocialData.tipoLogin === 'CHAVE_MOVEL') {
                    this.ssAskSms = true;
                    this.ssShowSmsError = false;
                }

                const dataObj = {
                    uuid: this.uuid,
                    tipoLogin: this.segSocialData.tipoLogin,
                    user: this.segSocialData.user,
                    password: this.segSocialData.password,
                    telefone: this.segSocialData.telefone,
                    pin: this.segSocialData.pin
                }

                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyHapiController.processSsData().url;
                axios.post(url, dataObj).then(function (/*response*/) {
                    self.$v.segSocialData.$reset();
                    self.sendingSs = false;
                    self.ssAskSms = false;
                    // TODO msgs
                    showSuccessDialog("Sucesso", "Dados registados. Obrigado!")
                    self.loadData();
                }).catch(function (error) {
                    self.sendingSs = false
                    self.ssAskSms = false;
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            sendFinancas() {
                this.$v.financasData.$touch();
                if (this.$v.financasData.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                const self = this;
                self.sendingFinancas = true;
                if (this.financasData.tipoLogin === 'CHAVE_MOVEL') {
                    this.financasAskSms = true;
                    this.financasShowSmsError = false;
                }

                const dataObj = {
                    uuid: this.uuid,
                    tipoLogin: this.financasData.tipoLogin,
                    user: this.financasData.user,
                    password: this.financasData.password,
                    telefone: this.financasData.telefone,
                    pin: this.financasData.pin
                }

                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyHapiController.processFinanceData().url;
                axios.post(url, dataObj).then(function (/*response*/) {
                    self.$v.financasData.$reset();
                    self.sendingFinancas = false;
                    self.financasAskSms = false;
                    // TODO msgs
                    showSuccessDialog("Sucesso", "Dados registados. Obrigado!")
                    self.loadData();
                }).catch(function (error) {
                    self.sendingFinancas = false
                    self.financasAskSms = false;
                    // handle error
                    self.handleAjaxError(error);
                });
            },
            sendCodigoSms() {
                const self = this;
                if (this.sendingSs && !this.ssCodigoSms) {
                    this.ssShowSmsError = true;
                    return;
                }
                if (this.sendingFinancas && !this.financasCodigoSms) {
                    this.financasShowSmsError = true;
                    return;
                }

                self.sendingSmsCode = true;
                const dataObj = {
                    uuid: this.uuid,
                    type: this.sendingSs ? 'SocialSecurity' : 'PrivateTax',
                    smsCode: this.sendingSs ? this.ssCodigoSms : this.financasCodigoSms
                }

                // eslint-disable-next-line
                const url = jsRoutes.controllers.ProductReplyHapiController.processSmsCode().url;
                axios.post(url, dataObj).then(function (/*response*/) {
                    self.sendingSmsCode = false;
                    // TODO handle result
                }).catch(function (error) {
                    self.sendingSmsCode = false
                    // handle error
                    self.handleAjaxError(error);
                });

            }
        }
    }
</script>
