import Vue from "vue";
import Vuex from "vuex";
import * as Sentry from '@sentry/vue';

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: {
                type: "",
                createDate: null,
                data: null,
            },
            reconnectError: false,
        },
        showLoginModal: false,
        loginModalError: null,

        profile: {},

        notifications: [],
        alarmsNotClosedCount: 0,

        productPendingCount: 0
    },
    getters: {
        socket(state) {
            return state.socket;
        },
        profile(state) {
            return state.profile;
        },
        isAdmin(state) {
            return state.profile.securityRoleName === 'admin';
        },
        showLoginModal(state) {
            return state.showLoginModal;
        },
        loginModalError(state) {
            return state.loginModalError;
        },
        notifications(state) {
            return state.notifications;
        },
        alarmsNotClosedCount(state) {
            return state.alarmsNotClosedCount;
        },
        productPendingCount(state) {
            return state.productPendingCount;
        },
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            console.log("socket connected!");
        },
        SOCKET_ONCLOSE(state/*, event*/) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error("Socket error: " + JSON.stringify(event))
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            console.log("message => " + JSON.stringify(message));
            if (message.type === 'NOTIFICATION') {
                state.notifications.push(message.data);
            } else if (message.type === 'NOTIFICATION_LIST') {
                state.notifications = message.data;
            } else if (message.type === 'ALARMS_NOT_CLOSED_COUNT') {
                state.alarmsNotClosedCount = message.data;
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, "Socket reconnect retry => " + count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
            console.error("RECONNECT Socket error: " + JSON.stringify(event))
        },
        'SET_PROFILE'(state, payload) {
            state.profile = payload;
            Sentry.setUser({ username: state.profile.email })
        },
        'SHOW_LOGIN_MODAL'(state, show) {
            state.showLoginModal = show;
            state.loginModalError = null;
            if (show) {
                Sentry.configureScope(scope => scope.setUser(null));
            }
        },
        'SET_LOGIN_MODAL_ERROR'(state, msg) {
            state.loginModalError = msg;
        },
        'SET_NOTIFICATIONS'(state, payload) {
            state.notifications = payload;
        },
        'ADD_NOTIFICATIONS'(state, payload) {
            state.notifications.push(payload);
        },
        'SET_PRODUCT_PENDING_COUNT'(state, payload) {
            state.productPendingCount = payload;
        },
    },
    actions: {
        setProfile({commit}, payload) {
            commit('SET_PROFILE', payload);
        },
        setNotifications({commit}, payload) {
            commit('SET_NOTIFICATIONS', payload);
        },
        addNotification({commit}, payload) {
            commit('ADD_NOTIFICATION', payload);
        },
        loadProfileData({commit}) {
            // eslint-disable-next-line
            let url = jsRoutes.controllers.Account.loadUserProfile().url
            return axios.get(url).then(response => {
                commit('SET_PROFILE', response.data);
                return response.data;
            }, error => {
                throw error;
            })
        },
        setShowLoginModal({commit}, payload) {
            commit('SHOW_LOGIN_MODAL', payload);
        },
        setShowLoginModalExpired({commit}) {
            commit('SHOW_LOGIN_MODAL', true);
            commit('SET_LOGIN_MODAL_ERROR', 'Your session expired. Please login again.');
        },
        loadProductPendingCount({commit}) {
            const self = this;
            // eslint-disable-next-line
            const url = jsRoutes.controllers.ProductController.getPendingCounter().url;

            // GET request
            axios.get(url).then(function (response) {
                // set data
                commit('SET_PRODUCT_PENDING_COUNT', response.data);
            }).catch(function (error) {
                // handle error
                self.handleAjaxError(error);
            });
        },
    },
    modules: {}
});
